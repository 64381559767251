(() => {
  const params = new URLSearchParams(window.location.search);
  const domainToCheck = params.get('checkavailability');

  if (!domainToCheck) {
    return;
  }

  const autoSearchForm: HTMLFormElement | null = document.querySelector('.api-search-form');

  if (autoSearchForm) {
    const input = autoSearchForm.querySelector('input');
    const button = autoSearchForm.querySelector('button');

    if (!input || !button) {
      return;
    }

    setTimeout(() => {
      input.value = domainToCheck;
      button.click();
    }, 100);
  }
})();
